import { getPageLang } from '../getPageLang'

// 葡萄牙语
const pt = {
  lang: {
    Chinese: 'Chinês simplificado chinês',
    English: 'inglês',
    Spanish: 'Língua espanhola',
    Indonesian: 'Língua indonésia',
    Portuguese: 'Português do brasil',
    Vietnamese: 'Língua vietnamita vietnamita',
    Thai: 'O tailandês',
    Turkish: 'Em turco'
  },
  menus: {
    index: 'Ficha técnica',
    work: 'Como trabalhar',
    calculator: 'Calculadora de comissões',
    contact: 'Fale conosco'
  },
  common: {
    usecard: 'Ganhe renda',
    makeMoney: ' de meio ',
    easy: 'período facilmente.',

    idxset: 'Fornecer configurações para você',
    idxsetInfo: 'A configuração é fornecida. Você só precisa enviar as informações necessárias para nós.',
    idxManage: 'Ensine técnicas de operação',
    idxManageInfo: 'Estamos sempre disponíveis para responder às suas dúvidas',
    idxProfit: 'Baixo limiar alto rendimento',
    idxProfitInfo: 'Fácil de operar, você pode ganhar comissões a qualquer momento, em qualquer lugar com um telefone celular',

    wkTinfo: 'Você só precisa fornecer as informações necessárias para receber e enviar o pagamento.',
    wkSecinfo: 'Forneceremos o pedido de pagamento de cobrança ou envio de pagamento. Você acabou de completá-lo de acordo com as instruções.',
    wkJoinus: 'Entre em contato conosco seja nosso funcionário',
    wkLogin: 'Entrar no backoffice de trabalho',
    wkBind: 'Vincular seu software de recebimento de pagamentos',
    wkMakemoney: 'Operação simples e ganhe comissões',
    wkBtnInfo_1: 'Vamos automatizar dados e operações repetitivas ao máximo,',
    wkBtnInfo_2: 'Tudo o que resta é mudar o software de cobrança e continuar',
    wkBtnInfo_3: 'Conduzir nossos negócios.',

    calTinfo_1: 'Abaixo está uma estimativa de cálculo de comissão que projetamos para os trabalhadores.',
    calTinfo_2: 'Você pode calcular a comissão que você pode receber depois de aceitar o trabalho, de acordo com sua situação real e inserir as informações relevantes.',
    calDayGet: 'Quanto você pode colher por dia',
    calDayPay: 'Quanto você pode pagar por dia',
    calDayPayNum: 'Quanto pode pagar por dia',
    calDayGetNum: 'Quanto pode ganhar por dia',
    calDayNum: 'Quanto pode trabalhar por mês',
    calUnit_mn: 'dinheiro',
    calUnit_count: 'transação.',
    calDay: 'dias',
    calInputTip: 'Por favor insira os números',
    calTocal: 'Calcule agora mesmo',
    calAnswerTip: 'A comissão mensal que você pode receber é',

    tatTitle: 'Por que nos escolher',
    tatCnt_1: 'Trabalhamos com',
    tatCnt_1_green: ' Qualquer país, várias plataformas para colaborar.',
    tatCnt_2: '',
    tatCnt_2_green: ' Transparência no trabalho',
    tatCnt_2_1: ', veja os benefícios em tempo real a qualquer momento',
    tatCnt_3: '',
    tatCnt_3_green: ' Fluxo de trabalho simples',
    tatCnt_3_1: ', basta ter um software de recebimento de pagamento',
    tatCnt_4: '',
    tatCnt_4_green: ' Alto rendimento',
    tatCnt_4_1: ', um dia pode ganhar 1000-5000',
    tatTinfo: 'Nosso gerente irá ajudá-lo a começar a trabalhar conosco, consultar todas as suas perguntas e mostrar-lhe como começar a ganhar no menor tempo possível',
    tatTel: 'Telegarm',
    tatApp: 'Whatsapp',
    tatE_mail: 'E_mail',
  }
}
getPageLang(pt, 'pt');   //获取每个页面的语言配置

export default pt

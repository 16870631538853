export default {
  todayBalance: {
    'zh-CN': '今日代收总额(元)',
    'en': 'today balance($)'
  },
  todayNum: {
    'zh-CN': '今日代收笔数(笔)',
    'en': 'today number(count)'
  },
  todayMyComs: {
    'zh-CN': '今日获得提成(元)',
    'en': 'today my commission($)'
  },
  todaySingleComs: {
    'zh-CN': '今日单笔奖励金额(元)',
    'en': 'today Single reward($)'
  },
  todaySubComs: {
    'zh-CN': '今日下级提成(元)',
    'en': 'today subordinate commission($)'
  },
  yesdayAmount: {
    'zh-CN': '昨日代收总额(元)',
    'en': 'yesterday amount($)'
  },
  yesdayNum: {
    'zh-CN': '昨日代收笔数(笔)',
    'en': 'yesterday number(count)'
  },
  yesdayComs: {
    'zh-CN': '昨日获得提成(元)',
    'en': 'yesterday my commission($)'
  },
  yesdaySingleComs: {
    'zh-CN': '昨日单笔奖励金额(元)',
    'en': 'yesterday Single reward($)'
  },
  yesdaySubComs: {
    'zh-CN': '昨日获得下级提成(元)',
    'en': 'yesterday subordinate commission($)'
  },
  depositTotal: {
    'zh-CN': '缴纳押金额度(元)',
    'en': 'deposit payment($)'
  },
  remainingLimit: {
    'zh-CN': '剩余可接单额度(元)',
    'en': 'remaining limit($)'
  }
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './rem/rem'
import i18n from "./i18n"

Vue.config.productionTip = false

//给当前设备添加标识
let _isPC = window.matchMedia("(min-width: 1367px)").matches;
let _isPhone = window.matchMedia("(max-width: 750px)").matches;
Vue.prototype.$client = _isPhone ? 'phone' : 'PC';
console.log('当前设备类型-----', Vue.prototype.$client);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

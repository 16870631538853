/**
 * 更新语言系统
*/
export const updateI18n = (that) => {
  let langFileds = require.context('./config', false, /\.js$/);
  let locale = window.localStorage.getItem('lang');

  langFileds.keys().forEach(key => {
    let regExp = /\.\/([^\.\/]+)\.([^\.]+)$/;   //正则用于匹配 ./en.js中的'en'
    let prop = regExp.exec(key)[1];   //正则匹配en|zh-CN这样的值
    let _pdata = langFileds(key).default;
    getPageLang(_pdata, locale);    //获取最新文件内容
    that.$i18n.messages[prop] = _pdata;
    that.$i18n.locale = locale;
  })
}

/**
 * 获取页面对应的语言展示
 * name: 页面js中语言对应的key (如:zh-CN)
*/
export const getPageLang = (obj, name) => {
  let _lang = window.localStorage.getItem('lang');
  if (_lang != name) return;

  let langFileds = require.context('./pages', false, /\.js$/);
  langFileds.keys().forEach(key => {
    let regExp = /\.\/([^\.\/]+)\.([^\.]+)$/;   //正则用于匹配 ./en.js中的'en'
    let prop = regExp.exec(key)[1], _pdata = langFileds(key).default, _data = JSON.parse(JSON.stringify(_pdata));
    for (let i in _data){
      _data[i] = _data[i][name];
    }
    obj[prop] = _data;
  })
}

import Vue from 'vue'
import VueRouter from 'vue-router'

const Index = r => require.ensure([], () => r(require('@/views/Index')), 'index')

const Page404 = r => require.ensure([], () => r(require('@/components/error/404')), 'error')

Vue.use(VueRouter)

export const basic = [
  { path: '/', component: Index, name: "layindex", title: "" },
  { path: "/404", component: Page404, title: "404" }
]

const Any = [{ path: "*", redirect: "/index", title: "" }]

const routerPages = [
  { path: "/index", component: Index, name: "index", title: "" }
]

const router = new VueRouter({
  routes: basic.concat(routerPages).concat(Any)
})

export default router

import Vue from "vue"
import VueI18n from "vue-i18n"
Vue.use(VueI18n)

let messages = {}
let langFileds = require.context('./config', false, /\.js$/);
let regExp = /\.\/([^\.\/]+)\.([^\.]+)$/;   //正则用于匹配 ./en.js中的'en'
let locale = 'pt';//window.localStorage.getItem('lang'), _navlang = navigator.language;

langFileds.keys().forEach(key => {
  let prop = regExp.exec(key)[1];   //正则匹配en|zh-CN这样的值
  messages[prop] = langFileds(key).default;
  if (!locale){
    locale = 'pt';    //使用当前设备语言
    window.localStorage.setItem("lang", locale);
  }
})

export default new VueI18n({ locale, messages });   //locale:指定语言字段  messages:定义语言字段
